const API_URL = "https://giovannimargoto.com/Category";
// Função para buscar todas as categorias por Board ID
export const getCategoriesByBoard = async (boardId) => {
  try {
    const response = await fetch(`${API_URL}/GetCategoriesByBoard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: "string", ordenation: 0, boardId: boardId }),
    });

    if (!response.ok) {
      throw new Error("Erro ao buscar categorias.");
    }

    const categories = await response.json();
    return categories; // Retorna a lista de categorias
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error;
  }
};

// Função para criar uma nova categoria
export const createCategory = async (name, ordenation, boardId) => {
  try {
    const response = await fetch(`${API_URL}/Create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        ordenation: ordenation,
        boardId: boardId,
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao criar categoria.");
    }

    const data = await response.json();
    return data.message || "Categoria criada com sucesso."; // Mensagem de sucesso ou falha
  } catch (error) {
    console.error("Erro ao criar categoria:", error);
    throw error;
  }
};

// Função para atualizar uma categoria
export const updateCategory = async (category) => {
  try {
    const response = await fetch(API_URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(category),
    });

    if (!response.ok) {
      throw new Error("Erro ao atualizar categoria.");
    }

    const data = await response.json();
    return data.message || "Categoria atualizada com sucesso."; // Mensagem de sucesso ou falha
  } catch (error) {
    console.error("Erro ao atualizar categoria:", error);
    throw error;
  }
};

export const updateCategoryOrder = async (category) => {
  try {
    const response = await fetch(`${API_URL}/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(category),
    });
    if (!response.ok) {
      throw new Error("Erro ao atualizar a ordenação da categoria.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error("Erro ao atualizar a categoria:", error);
    throw error;
  }
};

// Função para deletar uma categoria
export const deleteCategory = async (category) => {
  try {
    const response = await fetch(`${API_URL}/Delete`, {
      // Ajusta o endpoint para /Delete
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(category),
    });

    if (!response.ok) {
      throw new Error("Erro ao deletar categoria.");
    }

    const data = await response.json();
    return data.message || "Categoria deletada com sucesso."; // Mensagem de sucesso ou falha
  } catch (error) {
    console.error("Erro ao deletar categoria:", error);
    throw error;
  }
};
