import React, { useState } from "react";
import "./styles/login.css";
import logo from "./imgs/alibanban.png";
import { useNavigate } from "react-router-dom";
import { loginUser, registerUser } from "../services/Api";

function Login() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser(email, senha);
      if (response.user.id === 0) {
        setErrorMessage("Email ou senha incorretos. Tente novamente.");
      } else {
        localStorage.setItem("userId", response.user.id);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userName", response.user.name);
        navigate("/principal");
      }
    } catch (error) {
      console.error("Erro ao tentar fazer login:", error);
      setErrorMessage(
        "Ocorreu um erro ao tentar fazer login. Tente novamente."
      );
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCadastro = async (e) => {
    e.preventDefault();
    const nome = e.target[0].value;
    const email = e.target[1].value;
    const senha = e.target[2].value;
    const confirmarSenha = e.target[3].value;

    if (senha !== confirmarSenha) {
      alert("Senhas não conferem");
      return;
    }

    try {
      const isSuccess = await registerUser(email, senha, nome);
      if (isSuccess) {
        alert("Cadastro realizado com sucesso!");
        closeModal();
      } else {
        alert("Erro ao realizar cadastro!");
      }
    } catch (error) {
      console.error("Erro ao tentar cadastrar o usuário:", error);
      alert("Erro ao tentar cadastrar o usuário!");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-left">
          <img src={logo} alt="logo alibanban"></img>
          <button className="btn-cadastro" onClick={openModal}>
            Cadastrar-se
          </button>
        </div>
        <div className="login-right">
          <h2>LOGIN</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>E-mail</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
                required
              />
            </div>
            <div className="form-group">
              <label>Senha</label>
              <input
                type="password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                placeholder="Digite sua senha"
                required
              />
              <label className="underlabel">
                Esqueceu sua senha?{" "}
                <a href="https://www.google.com/search?q=rem%C3%A9dio+para+alzheimer">
                  Clique aqui
                </a>
              </label>
            </div>
            <button className="btn-login" type="submit">
              Entrar
            </button>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </form>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Cadastro</h2>
            <form onSubmit={handleCadastro}>
              <div className="form-group">
                <label>Nome</label>
                <input type="text" placeholder="Digite seu nome" required />
              </div>
              <div className="form-group">
                <label>E-mail</label>
                <input type="email" placeholder="Digite seu e-mail" required />
              </div>
              <div className="form-group">
                <label>Senha</label>
                <input
                  type="password"
                  placeholder="Digite sua senha"
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirmar Senha</label>
                <input
                  type="password"
                  placeholder="Confirme sua senha"
                  required
                />
              </div>
              <button type="submit" className="btn-cadastro">
                Cadastrar
              </button>
              <button type="button" className="btn-fechar" onClick={closeModal}>
                Fechar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
