const API_URL = "https://giovannimargoto.com";

export const createBoard = async (userId, title, description) => {
  try {
    const response = await fetch(`${API_URL}/Board/Create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        title: title,
        description: description,
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao criar o quadro.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const updateBoard = async (id, title, description) => {
  try {
    const response = await fetch(`${API_URL}/Board/Put`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        title: title,
        description: description,
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao atualizar o quadro.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const deleteBoard = async (id) => {
  try {
    const response = await fetch(`${API_URL}/Board/Delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao deletar o quadro.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const unvinculateUserFromBoard = async (boardId, userId) => {
  try {
    const response = await fetch(`${API_URL}/Board/Unvinculate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        board_id: boardId,
        user_id: userId,
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao desvincular o usuário do quadro.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const getBoardsByUser = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/Board/GetBoardsByUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
      }),
    });
    if (!response.ok) {
      throw new Error("Erro ao obter quadros do usuário.");
    }

    const boards = await response.json();
    return boards; // Retorna a lista de quadros
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const vinculateUserToBoard = async (boardId, userId) => {
  try {
    const response = await fetch(`${API_URL}/Board/Vinculate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        board_id: boardId,
        user_id: userId,
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao vincular o usuário ao quadro.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};
