const API_URL = "https://giovannimargoto.com";

/**
 * Função para obter tarefas por categoria.
 * @param {number} categoryId - O ID da categoria.
 * @returns {Promise<Array>} - Retorna uma lista de tarefas.
 */
export const getTasksByCategory = async (categoryId) => {
  try {
    const response = await fetch(`${API_URL}/AliTask/GetTasksByCategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: 0,
        title: "",
        content: "",
        ordenation: 0,
        category_ID: categoryId,
        color: "",
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao obter as tarefas.");
    }

    const tasks = await response.json();
    return tasks;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

/**
 * Função para criar uma nova tarefa.
 * @param {object} task - Um objeto que contém os dados da tarefa.
 * @returns {Promise<string>} - Retorna uma mensagem de sucesso.
 */
export const createTask = async (task) => {
  try {
    const response = await fetch(`${API_URL}/AliTask/Create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    });

    if (!response.ok) {
      throw new Error("Erro ao criar a tarefa.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

/**
 * Função para atualizar uma tarefa existente.
 * @param {object} task - Um objeto que contém os dados da tarefa.
 * @returns {Promise<string>} - Retorna uma mensagem de sucesso.
 */
export const updateTask = async (task) => {
  try {
    const response = await fetch(`${API_URL}/AliTask/Put`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    });

    if (!response.ok) {
      throw new Error("Erro ao atualizar a tarefa.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};
export const updateTaskOrderAndCategory = async (task) => {
  try {
    const response = await fetch(`${API_URL}/AliTask/Put`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    });

    if (!response.ok) {
      throw new Error("Erro ao atualizar a ordem e categoria da tarefa.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error("Erro ao atualizar a tarefa:", error);
    throw error;
  }
};

/**
 * Função para deletar uma tarefa.
 * @param {number} taskId - O ID da tarefa a ser deletada.
 * @returns {Promise<string>} - Retorna uma mensagem de sucesso.
 */
export const deleteTask = async (taskId) => {
  try {
    const response = await fetch(`${API_URL}/AliTask/Delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: taskId,
        title: "",
        content: "",
        ordenation: 0,
        category_ID: 0,
        color: "",
      }),
    });

    if (!response.ok) {
      throw new Error("Erro ao deletar a tarefa.");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};
