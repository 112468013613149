import React, { useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";

function TaskComponent({ task, onDelete, onDrop, onEdit }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedTask, setEditedTask] = useState({ ...task });

  const handleEditClick = () => {
    setEditedTask({ ...task });
    setIsEditModalOpen(true);
    onEdit(editedTask);
    setIsEditModalOpen(false);
  };

  const handleSaveEdit = () => {
    onEdit(editedTask);
    setIsEditModalOpen(false);
  };

  return (
    <div className="task-item" style={{ backgroundColor: task.color }}>
      <div className="task-header">
        <h3>{task.title}</h3>
        <div>
          <FaEdit className="edit-icon" onClick={handleEditClick} />
          <FaTrash className="delete-icon" onClick={onDelete} />
        </div>
      </div>
      <div className="task-divider"></div>
      <div className="task-content">
        <p>{task.content}</p>
      </div>

      {isEditModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Editar Task</h2>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <label>Título</label>
                <input
                  type="text"
                  value={editedTask.title}
                  onChange={(e) =>
                    setEditedTask({ ...editedTask, title: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Conteúdo</label>
                <textarea
                  value={editedTask.content}
                  onChange={(e) =>
                    setEditedTask({ ...editedTask, content: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Cor</label>
                <input
                  type="color"
                  value={editedTask.color}
                  onChange={(e) =>
                    setEditedTask({ ...editedTask, color: e.target.value })
                  }
                  required
                />
              </div>
              <button onClick={handleSaveEdit}>Salvar</button>
              <button onClick={() => setIsEditModalOpen(false)}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskComponent;
