import React from "react";
import { FaSignOutAlt, FaArrowLeft } from "react-icons/fa"; // Ícones de logout e voltar
import { useNavigate } from "react-router-dom"; // Para navegação
import "./styles/Header.css"; // Estilos para o header

function Header({ welcomeMessage, pageTitle }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear(); // Limpa o localStorage
    navigate("/login"); // Redireciona para a página de login
  };

  const handleBackClick = () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="header-icons" onClick={handleBackClick}>
          <FaArrowLeft className="icon" /> {/* Ícone de voltar */}
        </div>
        <div className="header-welcome">
          <h2>{welcomeMessage}</h2> {/* Mensagem de boas-vindas dinâmica */}
        </div>
        <div className="header-icons" onClick={handleLogout}>
          <FaSignOutAlt className="icon" /> {/* Ícone de logout */}
        </div>
      </div>
    </header>
  );
}

export default Header;
