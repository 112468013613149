const API_URL = "https://giovannimargoto.com/auth/User";

/**
 * Função para fazer o login do usuário.
 * @param {string} email - O email do usuário.
 * @param {string} password - A senha do usuário.
 * @returns {Promise<Object>} - Retorna os dados do usuário em caso de sucesso.
 * @throws {Error} - Lança um erro em caso de falha no login.
 */
export const loginUser = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/Login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    // Verifica status 401 - Usuário ou senha inválidos
    if (response.status === 401) {
      throw new Error("Usuário ou senha inválidos");
    }

    // Verifica outros erros (status fora da faixa 2xx)
    if (!response.ok) {
      throw new Error("Erro ao tentar fazer login");
    }

    // Retorna a resposta JSON em caso de sucesso
    return await response.json();
  } catch (error) {
    console.error("Erro no login:", error.message);
    throw error; // Lança o erro para ser tratado externamente
  }
};

/**
 * Função para cadastrar um novo usuário.
 * @param {string} email - O email do novo usuário.
 * @param {string} password - A senha do novo usuário.
 * @param {string} name - O nome do novo usuário.
 * @returns {Promise<boolean>} - Retorna um booleano indicando sucesso ou falha.
 */
export const registerUser = async (email, password, name) => {
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        name,
      }),
    });

    // Verifica se a resposta foi bem-sucedida (status 200 a 299)
    if (response.ok) {
      const data = await response.json();
      return true; // Indica que o cadastro foi bem-sucedido
    } else {
      const errorData = await response.json(); // Obtém detalhes do erro
      console.error("Erro:", errorData.message || "Erro ao cadastrar usuário");
      return false; // Indica falha no cadastro
    }
  } catch (error) {
    console.error("Erro ao tentar se conectar à API:", error);
    return false; // Indica falha no cadastro em caso de erro na conexão
  }
};

/**
 * Função para atualizar um usuário existente.
 * @param {string} email - O email do usuário a ser atualizado.
 * @param {string} password - A nova senha do usuário.
 * @param {string} name - O novo nome do usuário.
 * @returns {Promise<boolean>} - Retorna um booleano indicando sucesso ou falha.
 */
export const updateUser = async (email, password, name) => {
  const response = await fetch(API_URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      name,
    }),
  });

  if (!response.ok) {
    throw new Error("Erro ao tentar atualizar o usuário");
  }

  return response.json();
};

/**
 * Função para deletar um usuário.
 * @param {string} email - O email do usuário a ser deletado.
 * @param {string} password - A senha do usuário a ser deletado.
 * @returns {Promise<boolean>} - Retorna um booleano indicando sucesso ou falha.
 */
export const deleteUser = async (email, password) => {
  const response = await fetch(API_URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (!response.ok) {
    throw new Error("Erro ao tentar deletar o usuário");
  }

  return response.json();
};
