import React, { useState, useEffect } from "react";
import Header from "../components/Head";
import TaskComponent from "../components/Task";
import { FaTrash, FaPlus } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getCategoriesByBoard,
  createCategory,
  deleteCategory,
  updateCategoryOrder,
} from "../services/ApiCategory";
import {
  getTasksByCategory,
  createTask,
  deleteTask,
  updateTaskOrderAndCategory,
} from "../services/ApiTask";
import { useParams } from "react-router-dom";
import "./styles/Categorias.css";

function Categorias() {
  const { boardId } = useParams();
  const [categorias, setCategorias] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isEditingTask, setIsEditingTask] = useState(false);
  const [novaCategoria, setNovaCategoria] = useState({ name: "" });
  const [novaTask, setNovaTask] = useState({
    title: "",
    content: "",
    category_ID: null,
    color: "#000000",
  });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loadCategorias = async () => {
    setIsLoading(true);
    try {
      const response = await getCategoriesByBoard(boardId);
      const categoriesWithTasks = await Promise.all(
        response.map(async (category) => {
          const tasks = await getTasksByCategory(category.id);
          return { ...category, tasks };
        })
      );
      setCategorias(categoriesWithTasks);
    } catch (error) {
      setMessage("Erro ao carregar categorias.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (boardId) loadCategorias();
  }, [boardId]);

  const handleDeleteCategoria = async (id) => {
    const previousCategorias = [...categorias];
    const categoryToDelete = categorias.find((cat) => cat.id === id);

    if (!categoryToDelete) return;

    setCategorias(categorias.filter((cat) => cat.id !== id));
    try {
      await deleteCategory(categoryToDelete); // Envia o objeto completo
    } catch {
      setCategorias(previousCategorias);
      setMessage("Erro ao deletar categoria.");
    }
  };

  const handleAddCategoria = () => setIsModalOpen(true);

  const handleSaveCategoria = async (e) => {
    e.preventDefault();
    const newCategoria = {
      name: novaCategoria.name,
      ordenation: categorias.length + 1,
      board_ID: boardId,
      id: Date.now(),
    };
    setCategorias([...categorias, newCategoria]);
    try {
      const responseMessage = await createCategory(
        novaCategoria.name,
        categorias.length + 1,
        boardId
      );
      setMessage(responseMessage);
      await loadCategorias();
      setNovaCategoria({ name: "" });
      setIsModalOpen(false);
    } catch (error) {
      setCategorias(categorias);
      setMessage("Erro ao criar a categoria.");
    }
  };

  const handleAddTask = (categoryId) => {
    setNovaTask({
      title: "",
      content: "",
      category_ID: categoryId,
      color: "#FFFFFF",
    });
    setIsEditingTask(false);
    setIsTaskModalOpen(true);
  };

  const handleSaveTask = async (e) => {
    e.preventDefault();
    const { category_ID, title, content, color } = novaTask;
    const newTask = {
      category_ID,
      title,
      content,
      color,
      ordenation:
        categorias.find((cat) => cat.id === category_ID).tasks.length + 1,
    };

    // Atualiza as categorias localmente com a nova task e redefine as ordenações sequencialmente
    setCategorias((prev) =>
      prev.map((cat) => {
        if (cat.id === category_ID) {
          const updatedTasks = [...cat.tasks, newTask]
            .sort((a, b) => a.ordenation - b.ordenation)
            .map((task, index) => ({ ...task, ordenation: index + 1 })); // Garante ordenação sequencial
          return { ...cat, tasks: updatedTasks };
        }
        return cat;
      })
    );

    try {
      await createTask(newTask);
      await loadCategorias();
      setIsTaskModalOpen(false);
    } catch {
      setMessage("Erro ao criar tarefa.");
    }
  };

  const handleEditTaskClick = (task) => {
    setNovaTask(task); // Preenche o modal com os dados da task existente
    setIsEditingTask(true); // Define que o modal está em modo de edição
    setIsTaskModalOpen(true);
  };

  const handleEditTask = async (e) => {
    e.preventDefault();
    setCategorias((prevCategorias) =>
      prevCategorias.map((cat) =>
        cat.id === novaTask.category_ID
          ? {
              ...cat,
              tasks: cat.tasks.map((task) =>
                task.id === novaTask.id ? { ...task, ...novaTask } : task
              ),
            }
          : cat
      )
    );

    try {
      await updateTaskOrderAndCategory(novaTask);
      setIsTaskModalOpen(false);
      setIsEditingTask(false);
    } catch {
      setMessage("Erro ao editar tarefa.");
    }
  };

  const handleDeleteTask = async (taskId, categoryId) => {
    await deleteTask(taskId);
    setCategorias((prev) =>
      prev.map((cat) =>
        cat.id === categoryId
          ? { ...cat, tasks: cat.tasks.filter((task) => task.id !== taskId) }
          : cat
      )
    );
  };

  const handleTaskDrop = async (result) => {
    if (!result.destination || isTaskModalOpen) return;

    const { source, destination } = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return; // Não faz nada se a task foi solta sobre si mesma
    }

    const sourceCategory = categorias.find(
      (cat) => `category-${cat.id}` === source.droppableId
    );
    const destinationCategory = categorias.find(
      (cat) => `category-${cat.id}` === destination.droppableId
    );

    const [movedTask] = sourceCategory.tasks.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceCategory.tasks.splice(destination.index, 0, movedTask);
    } else {
      movedTask.category_ID = destinationCategory.id;
      destinationCategory.tasks.splice(destination.index, 0, movedTask);
    }

    const updateOrderForCategoryTasks = (category) => {
      category.tasks.forEach((task, index) => {
        if (task.ordenation !== index + 1) {
          task.ordenation = index + 1;
        }
      });
    };

    updateOrderForCategoryTasks(sourceCategory);
    if (sourceCategory !== destinationCategory) {
      updateOrderForCategoryTasks(destinationCategory);
    }

    setCategorias([...categorias]);

    try {
      const tasksToUpdate = [
        ...sourceCategory.tasks,
        ...destinationCategory.tasks,
      ];
      for (let task of tasksToUpdate) {
        await updateTaskOrderAndCategory(task);
      }
    } catch (error) {
      setMessage("Erro ao atualizar tarefa.");
    }
  };

  return (
    <div className="categorias-page">
      <Header welcomeMessage="Categorias do Quadro" pageTitle="Categorias" />
      <DragDropContext onDragEnd={!isTaskModalOpen ? handleTaskDrop : null}>
        <div className="categorias-container">
          {message && <p>{message}</p>}
          {isLoading ? (
            <div className="loading">Carregando categorias...</div>
          ) : (
            <div className="categorias-row">
              {categorias
                .sort((a, b) => a.ordenation - b.ordenation)
                .map((category) => (
                  <Droppable
                    key={category.id}
                    droppableId={`category-${category.id}`}
                  >
                    {(provided) => (
                      <div
                        className="categoria-coluna"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="categoria-header">
                          <span>{category.name}</span>
                          <FaTrash
                            className="delete-icon"
                            onClick={() => handleDeleteCategoria(category.id)}
                          />
                        </div>
                        <div className="tasks-container">
                          {category.tasks &&
                            category.tasks
                              .sort((a, b) => a.ordenation - b.ordenation)
                              .map((task, index) => (
                                <Draggable
                                  key={task.id}
                                  draggableId={`task-${task.id}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <TaskComponent
                                        task={task}
                                        onDelete={() =>
                                          handleDeleteTask(task.id, category.id)
                                        }
                                        onEdit={() => handleEditTaskClick(task)}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                          {provided.placeholder}
                          <div
                            className="add-task"
                            onClick={() => handleAddTask(category.id)}
                          >
                            <FaPlus />
                          </div>
                        </div>
                      </div>
                    )}
                  </Droppable>
                ))}
              <div className="add-categoria" onClick={handleAddCategoria}>
                <FaPlus />
              </div>
            </div>
          )}
        </div>
      </DragDropContext>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Nova Categoria</h2>
            <form onSubmit={handleSaveCategoria}>
              <div className="form-group">
                <label>Nome</label>
                <input
                  type="text"
                  value={novaCategoria.name}
                  onChange={(e) =>
                    setNovaCategoria({ ...novaCategoria, name: e.target.value })
                  }
                  required
                />
              </div>
              <button type="submit">Salvar</button>
              <button type="button" onClick={() => setIsModalOpen(false)}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
      {isTaskModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{isEditingTask ? "Editar Task" : "Nova Task"}</h2>
            <form onSubmit={isEditingTask ? handleEditTask : handleSaveTask}>
              <div className="form-group">
                <label>Título</label>
                <input
                  type="text"
                  value={novaTask.title}
                  onChange={(e) =>
                    setNovaTask({ ...novaTask, title: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Conteúdo</label>
                <textarea
                  value={novaTask.content}
                  onChange={(e) =>
                    setNovaTask({ ...novaTask, content: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Cor</label>
                <input
                  type="color"
                  value={novaTask.color}
                  onChange={(e) =>
                    setNovaTask({ ...novaTask, color: e.target.value })
                  }
                  required
                />
              </div>
              <button type="submit">Salvar</button>
              <button type="button" onClick={() => setIsTaskModalOpen(false)}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Categorias;
