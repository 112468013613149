import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importar o hook para navegação
import Header from "../components/Head";
import {
  createBoard,
  getBoardsByUser,
  deleteBoard,
} from "../services/ApiBoard"; // Funções para criar e buscar quadros da API
import "./styles/Principal.css"; // Estilos para a página principal
import { FaTrash, FaPlus } from "react-icons/fa"; // Ícones

function Principal() {
  const [quadros, setQuadros] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Controle do modal
  const [novoQuadro, setNovoQuadro] = useState({ titulo: "", descricao: "" }); // Estado para o novo quadro
  const [message, setMessage] = useState(""); // Mensagem para feedback
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento

  const userName = localStorage.getItem("userName"); // Pega o nome do usuário do localStorage
  const userId = localStorage.getItem("userId"); // Pega o ID do usuário logado no localStorage

  const navigate = useNavigate(); // Hook para navegação

  // Função para carregar os quadros
  const loadQuadros = async () => {
    setIsLoading(true); // Inicia o carregamento
    try {
      const response = await getBoardsByUser(userId); // Busca os quadros vinculados ao usuário
      console.log(response);
      setQuadros(response); // Atualiza o estado com os quadros
    } catch (error) {
      setMessage("Erro ao carregar quadros.");
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };

  // Carregar os quadros ao montar o componente
  useEffect(() => {
    if (userId) {
      loadQuadros();
    }
  }, [userId]);

  const handleDelete = async (id) => {
    // Deleta o quadro através da API
    await deleteBoard(id);
    loadQuadros();
  };

  // Função para navegar para a página de categorias ao clicar em um quadro
  const handleBoardClick = (boardId) => {
    console.log(boardId);
    navigate(`/categorias/${boardId}`); // Navega para a página de categorias, passando o id do quadro
  };

  const handleAddQuadro = () => {
    setIsModalOpen(true); // Abre o modal para adicionar novo quadro
  };

  const handleSaveQuadro = async (e) => {
    e.preventDefault();
    try {
      const responseMessage = await createBoard(
        userId,
        novoQuadro.titulo,
        novoQuadro.descricao
      );
      setMessage(responseMessage); // Exibe a mensagem de sucesso da criação
      await loadQuadros(); // Recarrega os quadros após criar um novo

      setNovoQuadro({ titulo: "", descricao: "" });
      setIsModalOpen(false); // Fecha o modal
    } catch (error) {
      setMessage("Erro ao criar o quadro.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Fecha o modal sem salvar
  };

  return (
    <div className="principal-page">
      <Header
        welcomeMessage={`Bem vindo, ${userName}!`}
        pageTitle="Meus Quadros"
      />
      <div className="principal-container">
        {message && <p>{message}</p>} {/* Exibe mensagens de feedback */}
        {isLoading ? (
          <div className="loading">Carregando quadros...</div>
        ) : (
          <div className="quadros-list">
            {quadros.map((quadro) => (
              <div
                key={quadro.id}
                className="quadro-item"
                onClick={() => handleBoardClick(quadro.id)} // Navega para a página de categorias ao clicar
              >
                <div className="quadro-header">
                  <span>{quadro.title}</span>
                  <FaTrash
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation(); // Evita que a navegação ocorra ao clicar na lixeira
                      handleDelete(quadro.id);
                    }}
                  />
                </div>
                <div className="quadro-descricao">
                  <p>{quadro.description}</p>
                </div>
              </div>
            ))}
            <div className="add-quadro" onClick={handleAddQuadro}>
              <FaPlus />
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Novo Quadro</h2>
            <form onSubmit={handleSaveQuadro}>
              <div className="form-group">
                <label>Título</label>
                <input
                  type="text"
                  value={novoQuadro.titulo}
                  onChange={(e) =>
                    setNovoQuadro({ ...novoQuadro, titulo: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Descrição</label>
                <textarea
                  value={novoQuadro.descricao}
                  onChange={(e) =>
                    setNovoQuadro({ ...novoQuadro, descricao: e.target.value })
                  }
                  required
                />
              </div>
              <button type="submit">Salvar</button>
              <button type="button" onClick={closeModal}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Principal;
