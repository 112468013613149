import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login";
import Principal from "./pages/Principal";
import Categorias from "./pages/Categorias";

function App() {
  // Função para verificar se o usuário está autenticado
  const isAuthenticated = !!localStorage.getItem("token"); // Simulação de autenticação com localStorage

  return (
    <Router>
      <Routes>
        {/* Se o usuário não estiver autenticado, redireciona para /login */}
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/login" : "/login"} />}
        />
        {/* Página de login */}
        <Route path="/login" element={<Login />} />
        <Route path="/principal" element={<Principal />} />
        <Route path="/categorias/:boardId" element={<Categorias />} />{" "}
        {/* Rota para categorias */}
        {/* Qualquer outra rota redireciona para login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
